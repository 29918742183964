import Block from '../block'

export default class MessageJs extends Block {
	static title = 'message-js'

	constructor($el) {
		super($el)

		$(document).on('message:success', (index, data) => {
			this.notify(data.message, 'success', 'check2-circle', data.duration)
		})

		$(document).on('message:warning', (index, data) => {
			this.notify(
				data.message,
				'warning',
				'exclamation-triangle',
				data.duration,
			)
		})

		$(document).on('message:error', (index, data) => {
			this.notify(data.message, 'danger', 'x-circle', data.duration)
		})
	}

	escapeHtml(html) {
		const div = document.createElement('div')
		div.textContent = html
		return div.innerHTML
	}

	notify(message, variant, icon, duration = 5000) {
		const alert = Object.assign(document.createElement('sl-alert'), {
			variant,
			closable: true,
			duration: duration,
			countdown: 'rtl',
			innerHTML: `
                <sl-icon name="${icon}" slot="icon"></sl-icon>
                <strong>${this.escapeHtml(message)}</strong>
            `,
		})

		this.$el.append(alert)
		return alert.toast()
	}
}

import Block from '../block'

export default class ShoppingcartPayment extends Block {
	static title = 'shoppingcart-payment'

	constructor($el) {
		super($el)

		this.img = this.$el.findElement('method-img')
		this.select = this.$el.find('sl-select[name="method_id"]')

		if (!this.$el.hasModifier('autosubmit')) {
			this.select.on('sl-change', (e) => {
				const current = $(e.currentTarget).find(
					'sl-option[aria-selected="true"]',
				)
				if (current.val()) {
					this.img
						.find('img')
						.attr(
							'src',
							'https://static.pay.nl/payment_profiles/100x100/' +
								current.val() +
								'.png',
						)
						.attr('alt', current.data('title'))
					this.img.addClass('flex')
					this.img.removeClass('hidden')
				} else {
					this.img.removeClass('flex')
					this.img.addClass('hidden')
				}
			})
		}

		if (this.$el.hasModifier('autosubmit')) {
			const paymentProviderSelect = this.$el.find(
				'sl-select[name="payment_id"]',
			)
			paymentProviderSelect.on('sl-change', () => {
				this.$el.submit()
			})
		}
	}
}

import 'magnific-popup'
import Block from '../block'

export default class BlockThumbnail extends Block {
	static title = 'block-thumbnail'

	constructor($el) {
		super($el)

		this.images = this.$el.find('.block-thumbnail__image')
		this.images.removeClass('block-thumbnail__image--loading')

		this.images.magnificPopup({
			type: 'image',
			gallery: {
				enabled: true,
				navigationByImageClick: true,
				preload: [0, 1],
			},
			allowHTMLInTemplate: true,
			showCloseBtn: false,
			image: {
				markup:
					'<div class="mfp-figure">' +
					'<div class="mfp-close"></div>' +
					'<div class="mfp-img"></div>' +
					'<div class="mfp-bottom-bar">' +
					'<div class="mfp-title"></div>' +
					'</div>' +
					'</div>',

				titleSrc: function (item) {
					if (!item.el.attr('title') && !item.el.attr('description')) {
						return null
					}

					return (
						'<div class="mfp-title-container">' +
						'<div class="mfp-title-text">' +
						item.el.attr('title') +
						'</div>' +
						'<div class="mfp-title-description">' +
						item.el.attr('description') +
						'</div>' +
						'</div>'
					)
				},
			},

			tLoading: `Loading...`,

			callbacks: {
				change: (el) => {
					this.isLoaded(el)
				},
			},
		})

		window.onresize = () => {
			this.clickedImage = { img: $('.mfp-img') }

			this.changeStyling(this.clickedImage)
		}
	}

	isLoaded(image) {
		setTimeout(() => {
			if (
				image.loaded &&
				!image.isCheckingImgSize &&
				image.img.is(':visible')
			) {
				this.changeStyling(image)
			} else {
				this.isLoaded(image)
			}
		}, 100)
	}

	changeStyling(el) {
		$('.mfp-arrow-left').css(
			'left',
			el.img.get(0).getBoundingClientRect().left - 32,
		)
		$('.mfp-arrow-right').css(
			'left',
			el.img.get(0).getBoundingClientRect().right - 32,
		)
		$('.mfp-bottom-bar').css('width', el.img.width())
	}
}

import Block from '../block'

export default class Preview extends Block {
	static title = 'preview'

	constructor($el) {
		super($el)

		this.iframe = this.$el.findElement('iframe')
		this.buttons = this.$el.findElement('nav-right-icon')
		this.backButton = this.$el.findElement('back')

		this.buttons.on('click', (el) => {
			this.buttons.removeClass('preview__nav-right-icon--active')
			$(el.currentTarget).addClass('preview__nav-right-icon--active')

			switch ($(el.currentTarget).data('type')) {
				case 'desktop':
					this.iframe.get(0).classList = 'preview__iframe w-full h-full'
					break

				case 'tablet':
					this.iframe.get(0).classList =
						'preview__iframe w-[1180px] h-[820px] border-2 border-black'
					break

				case 'mobile':
					this.iframe.get(0).classList =
						'preview__iframe w-[414px] h-[896px]  border-2 border-black'
					break
			}
		})

		this.backButton.on('click', () => {
			window.close()
		})
	}
}
